import { render, staticRenderFns } from "./DisciplineandAttendance.vue?vue&type=template&id=ac5b875e&scoped=true"
import script from "./DisciplineandAttendance.vue?vue&type=script&lang=ts"
export * from "./DisciplineandAttendance.vue?vue&type=script&lang=ts"
import style0 from "./DisciplineandAttendance.vue?vue&type=style&index=0&id=ac5b875e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac5b875e",
  null
  
)

export default component.exports