import Chart, { ChartConfiguration, ChartItem } from "chart.js/auto";

const labels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const zoomOptions = {
  limits: {
    x: { min: "original", max: "original", minRange: 60 * 1000 },
  },
  pan: {
    enabled: true,
    mode: "x",
    modifierKey: "ctrl",
    //onPanComplete: startFetch
  },
  zoom: {
    wheel: {
      enabled: true,
    },
    drag: {
      enabled: true,
    },
    pinch: {
      enabled: true,
    },
    mode: "x",
    //onZoomComplete: startFetch
  },
};

export const drawBarChart = (
  id: string,
  pielabel: string[] | number[],
  givenData: number[] | string[],
  hasDataSet: boolean
) => {
  const chartDiv = document.getElementById(id);
  const labels = pielabel;
  function checkDataType() {
    if (hasDataSet) {
      return givenData;
    } else {
      return [
        {
          label: "",
          backgroundColor: "#385463",
          borderColor: "#493698",
          data: givenData,
          useLineStyle: true,
          usePointStyle: true,
        },
      ];
    }
  }
  const data = {
    labels: labels,
    datasets: checkDataType(),
  };
  
  const config = {
    type: "bar",
    data: data,
    options: {
      scales: {
       y:{
        beginAtZero:true
       }
      },
    },
  };
  
  if (chartDiv) {
    const myChart = new Chart(
      chartDiv as ChartItem,
      config as ChartConfiguration
    );
    console.log("bar chart id ", myChart.id);
    return myChart;
  }
};
