
import { Component, Mixins } from "vue-property-decorator";
import { mapState } from "vuex";
import InfoModal from "@/components/InfoModal.vue";
import { drawBarChart } from "@/assets/js/bar-chart";
import { Chart } from "chart.js";
import ScrollToTopMixin from "@/mixins/scroll-to-top.mixin";
import SchoolMixin from "@/mixins/school.mixin";
import { isEmpty, isNull } from "lodash";

@Component({
  components: {
    InfoModal,
  },
  computed: { ...mapState("app", ["mobileView", "tabletView"]) },
  metaInfo: {
    title: "Discipline and Attendance page",
  },
})
export default class DisciplineandAttendance extends Mixins(ScrollToTopMixin, SchoolMixin) {
  mobileView!: boolean;
  tabletView!: boolean;
  showItemDetail = false;
  dataList: string[] = [];
  selectedHeaderText = "";
  viewSideBarInfo = false;
  viewSideBarDetails = false;
  givenTitle = "";
  givenDescription = "";
  
  studentMissingInversePercentage = "";
  studentMissingDistrictResultInversePercentage = "";
  studentMissingStateResultInversePercentage = "";
  
  studentMissingEconomicDisadvantageSchoolResultInversePercentage = "";
  studentMissingEconomicDisadvantageDistrictResultInversePercentage = "";
  studentMissingEconomicDisadvantageStateResultInversePercentage = "";
  
  studentMissingDisabilitySchoolResultInversePercentage = "";
  studentMissingDisabilityDistrictResultInversePercentage = "";
  studentMissingDisabilityStateResultInversePercentage = "";
  
  studentMissingEllSchoolResultInversePercentage = "";
  studentMissingEllDistrictResultInversePercentage = "";
  studentMissingEllStateResultInversePercentage = "";
  
  studentMissingMaleSchoolResultInversePercentage = "";
  studentMissingMaleDistrictResultInversePercentage = "";
  studentMissingMaleStateResultInversePercentage = "";
  
  studentMissingFemaleSchoolResultInversePercentage = "";
  studentMissingFemaleDistrictResultInversePercentage = "";
  studentMissingFemaleStateResultInversePercentage = "";
  
  studentMissingFosterSchoolResultInversePercentage = "";
  studentMissingFosterDistrictResultInversePercentage = "";
  studentMissingFosterStateResultInversePercentage = "";
  
  studentMissingHomelessSchoolResultInversePercentage = "";
  studentMissingHomelessDistrictResultInversePercentage = "";
  studentMissingHomelessStateResultInversePercentage = "";  
  
  studentMissingMigrantSchoolResultInversePercentage = "";
  studentMissingMigrantDistrictResultInversePercentage = "";
  studentMissingMigrantStateResultInversePercentage = "";
  
  studentMissingAsianSchoolResultInversePercentage = "";
  studentMissingAsianDistrictResultInversePercentage = "";
  studentMissingAsianStateResultInversePercentage = "";
  
  studentMissingBlackSchoolResultInversePercentage = "";
  studentMissingBlackDistrictResultInversePercentage = "";
  studentMissingBlackStateResultInversePercentage = "";
    
  studentMissingHispanicSchoolResultInversePercentage = "";
  studentMissingHispanicDistrictResultInversePercentage = "";
  studentMissingHispanicStateResultInversePercentage = "";
  
  studentMissingMultipleRacesSchoolResultInversePercentage = "";
  studentMissingMultipleRacesDistrictResultInversePercentage = "";
  studentMissingMultipleRacesStateResultInversePercentage = "";
  
  studentMissingNativeAmericanSchoolResultInversePercentage = "";
  studentMissingNativeAmericanDistrictResultInversePercentage = "";
  studentMissingNativeAmericanStateResultInversePercentage = "";

  studentMissingPacificIslanderSchoolResultInversePercentage = "";
  studentMissingPacificIslanderDistrictResultInversePercentage = "";
  studentMissingPacificIslanderStateResultInversePercentage = "";
  
  studentMissingWhiteSchoolResultInversePercentage = "";
  studentMissingWhiteDistrictResultInversePercentage = "";
  studentMissingWhiteStateResultInversePercentage = "";
  
  sideBarDetailsTitle = "";
  sideBarInfo = "";

  studentZeroSuspensionsPercentage = "";
  studentZeroSuspensionsDistrictResultPercentage = "";
  studentZeroSuspensionsStateResultPercentage = "";

  studentZeroSuspensionsEconomicDisadvantageSchoolResultPercentage = "";
  studentZeroSuspensionsEconomicDisadvantageDistrictResultPercentage = "";
  studentZeroSuspensionsEconomicDisadvantageStateResultPercentage = "";

  studentZeroSuspensionsStudentOfColourSchoolResultPercentage = "";
  studentZeroSuspensionsStudentOfColourDistrictResultPercentage = "";
  studentZeroSuspensionsStudentOfColourStateResultPercentage = "";

  studentZeroSuspensionsDisabilitySchoolResultPercentage = "";
  studentZeroSuspensionsDisabilityDistrictResultPercentage = "";
  studentZeroSuspensionsDisabilityStateResultPercentage = "";

  studentZeroSuspensionsEllSchoolResultPercentage = "";
  studentZeroSuspensionsEllDistrictResultPercentage = "";
  studentZeroSuspensionsEllStateResultPercentage = "";

  showModal(type: string) {
    this.selectedHeaderText = "";
    this.dataList = [];
    this.viewSideBarInfo = true;
    this.viewSideBarDetails = false;
    switch (type) {
      case "info":
        this.givenTitle = "What is Discipline and Attendance?";
        this.givenDescription = "Regular attendance is essential for success in school. When students attend school regularly, their appreciation for learning grows, conduct improves, and academic performance improves.";
        break;
      case "studentMissing":
        this.givenTitle = "Why 15 days?";
        this.givenDescription = "Students who miss 15 or more school days (approximately 10% of the school year) for any reason are considered chronically absent. Research shows that chronic absenteeism is associated with a number of negative consequences for students, including lower achievement, disengagement from school, course failure, and increased risk of dropping out.";
        break;
      default:
        this.viewSideBarInfo = false;
        this.givenTitle = "";
        this.givenDescription = "";
        break;
    }
  }

  showVideoModal() {
    this.selectedHeaderText = "";
    this.dataList.push("https://www.youtube.com/embed/MRHD5A_WkrQ");
    this.showItemDetail = true;
  }

  get sidebarWidth(): string {
    let result = "";
    if (this.mobileView) {
      result = "100%";
    } else if (this.tabletView) result = "40%";
    return result;
  }

  created() {
    this.studentMissingInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissing?.trim());
    this.studentMissingDistrictResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingDistrictResult?.trim());
    this.studentMissingStateResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingStateResult?.trim());

    this.studentMissingEconomicDisadvantageSchoolResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingEconomicDisadvantageSchoolResult?.trim());
    this.studentMissingEconomicDisadvantageDistrictResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingEconomicDisadvantageDistrictResult?.trim());
    this.studentMissingEconomicDisadvantageStateResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingEconomicDisadvantageStateResult?.trim());

    this.studentMissingDisabilitySchoolResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingDisabilitySchoolResult?.trim());
    this.studentMissingDisabilityDistrictResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingDisabilityDistrictResult?.trim());
    this.studentMissingDisabilityStateResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingDisabilityStateResult?.trim());

    this.studentMissingEllSchoolResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingEllSchoolResult?.trim());
    this.studentMissingEllDistrictResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingEllDistrictResult?.trim());
    this.studentMissingEllStateResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingEllStateResult?.trim());
            
    this.studentMissingMaleSchoolResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingMaleSchoolResult?.trim());
    this.studentMissingMaleDistrictResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingMaleDistrictResult?.trim());
    this.studentMissingMaleStateResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingMaleStateResult?.trim());

    this.studentMissingFemaleSchoolResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingFemaleSchoolResult?.trim());
    this.studentMissingFemaleDistrictResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingFemaleDistrictResult?.trim());
    this.studentMissingFemaleStateResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingFemaleStateResult?.trim());
    
    this.studentMissingFosterSchoolResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingFosterSchoolResult?.trim());
    this.studentMissingFosterDistrictResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingFosterDistrictResult?.trim());
    this.studentMissingFosterStateResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingFosterStateResult?.trim());

    this.studentMissingHomelessSchoolResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingHomelessSchoolResult?.trim());
    this.studentMissingHomelessDistrictResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingHomelessDistrictResult?.trim());
    this.studentMissingHomelessStateResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingHomelessStateResult?.trim());

    this.studentMissingMigrantSchoolResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingMigrantSchoolResult?.trim());
    this.studentMissingMigrantDistrictResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingMigrantDistrictResult?.trim());
    this.studentMissingMigrantStateResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingMigrantStateResult?.trim());
        
    this.studentMissingAsianSchoolResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingAsianSchoolResult?.trim());
    this.studentMissingAsianDistrictResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingAsianDistrictResult?.trim());
    this.studentMissingAsianStateResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingAsianStateResult?.trim());

    this.studentMissingBlackSchoolResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingBlackSchoolResult?.trim());
    this.studentMissingBlackDistrictResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingBlackDistrictResult?.trim());
    this.studentMissingBlackStateResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingBlackStateResult?.trim());

    this.studentMissingHispanicSchoolResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingHispanicSchoolResult?.trim());
    this.studentMissingHispanicDistrictResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingHispanicDistrictResult?.trim());
    this.studentMissingHispanicStateResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingHispanicStateResult?.trim());

    this.studentMissingMultipleRacesSchoolResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingMultipleRacesSchoolResult?.trim());
    this.studentMissingMultipleRacesDistrictResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingMultipleRacesDistrictResult?.trim());
    this.studentMissingMultipleRacesStateResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingMultipleRacesStateResult?.trim());

    this.studentMissingNativeAmericanSchoolResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingNativeAmericanSchoolResult?.trim());
    this.studentMissingNativeAmericanDistrictResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingNativeAmericanDistrictResult?.trim());
    this.studentMissingNativeAmericanStateResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingNativeAmericanStateResult?.trim());

    this.studentMissingPacificIslanderSchoolResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingPacificIslanderSchoolResult?.trim());
    this.studentMissingPacificIslanderDistrictResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingPacificIslanderDistrictResult?.trim());
    this.studentMissingPacificIslanderStateResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingPacificIslanderStateResult?.trim());
    
    this.studentMissingWhiteSchoolResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingWhiteSchoolResult?.trim());
    this.studentMissingWhiteDistrictResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingWhiteDistrictResult?.trim());
    this.studentMissingWhiteStateResultInversePercentage = this.getInversePercentValue(this.school?.disciplineAndAttendance?.studentMissingWhiteStateResult?.trim());

    this.studentZeroSuspensionsPercentage = this.getZeroSuspensionPercentValue(this.school?.disciplineAndAttendance?.studentZeroSuspensions?.trim());
    this.studentZeroSuspensionsDistrictResultPercentage = this.getZeroSuspensionPercentValue(this.school?.disciplineAndAttendance?.studentZeroSuspensionsDistrictResult?.trim());
    this.studentZeroSuspensionsStateResultPercentage = this.getZeroSuspensionPercentValue(this.school?.disciplineAndAttendance?.studentZeroSuspensionsStateResult?.trim());

    this.studentZeroSuspensionsEconomicDisadvantageSchoolResultPercentage = this.getZeroSuspensionPercentValue(this.school?.disciplineAndAttendance?.studentZeroSuspensionsEconomicDisadvantageSchoolResult?.trim());
    this.studentZeroSuspensionsEconomicDisadvantageDistrictResultPercentage = this.getZeroSuspensionPercentValue(this.school?.disciplineAndAttendance?.studentZeroSuspensionsEconomicDisadvantageDistrictResult?.trim());
    this.studentZeroSuspensionsEconomicDisadvantageStateResultPercentage = this.getZeroSuspensionPercentValue(this.school?.disciplineAndAttendance?.studentZeroSuspensionsEconomicDisadvantageStateResult?.trim());

    this.studentZeroSuspensionsStudentOfColourSchoolResultPercentage = this.getZeroSuspensionPercentValue(this.school?.disciplineAndAttendance?.studentZeroSuspensionsStudentOfColourSchoolResult?.trim());
    this.studentZeroSuspensionsStudentOfColourDistrictResultPercentage = this.getZeroSuspensionPercentValue(this.school?.disciplineAndAttendance?.studentZeroSuspensionsStudentOfColourDistrictResult?.trim());
    this.studentZeroSuspensionsStudentOfColourStateResultPercentage = this.getZeroSuspensionPercentValue(this.school?.disciplineAndAttendance?.studentZeroSuspensionsStudentOfColourStateResult?.trim());

    this.studentZeroSuspensionsDisabilitySchoolResultPercentage = this.getZeroSuspensionPercentValue(this.school?.disciplineAndAttendance?.studentZeroSuspensionsDisabilitySchoolResult?.trim());
    this.studentZeroSuspensionsDisabilityDistrictResultPercentage = this.getZeroSuspensionPercentValue(this.school?.disciplineAndAttendance?.studentZeroSuspensionsDisabilityDistrictResult?.trim());
    this.studentZeroSuspensionsDisabilityStateResultPercentage = this.getZeroSuspensionPercentValue(this.school?.disciplineAndAttendance?.studentZeroSuspensionsDisabilityStateResult?.trim());

    this.studentZeroSuspensionsEllSchoolResultPercentage = this.getZeroSuspensionPercentValue(this.school?.disciplineAndAttendance?.studentZeroSuspensionsEllSchoolResult?.trim());
    this.studentZeroSuspensionsEllDistrictResultPercentage = this.getZeroSuspensionPercentValue(this.school?.disciplineAndAttendance?.studentZeroSuspensionsEllDistrictResult?.trim());
    this.studentZeroSuspensionsEllStateResultPercentage = this.getZeroSuspensionPercentValue(this.school?.disciplineAndAttendance?.studentZeroSuspensionsEllStateResult?.trim());
        
    console.log("BIPLAB CREATED");

    console.log(
      this.studentZeroSuspensionsEllSchoolResultPercentage
    );
    console.log(
      Number(
        this.school?.disciplineAndAttendance?.studentZeroSuspensionsEllSchoolResult
      )
    );

    console.log(
      isNaN(
        Number(
          this.school?.disciplineAndAttendance?.studentZeroSuspensionsEllSchoolResult
        )
      )
    );
  }

  currentBarChart: Chart | undefined;
  chartIsCreated = false;

  viewDetails(InfoSideBar: string) {
    this.viewSideBarInfo = false;
    this.viewSideBarDetails = true;
    this.sideBarInfo = InfoSideBar;

    if (InfoSideBar == "StudentMissing") {
      this.sideBarDetailsTitle = "Students missing 15 or more days of school";
    }
    else if (InfoSideBar == "ZeroSuspension") {
      this.sideBarDetailsTitle = "Students with zero out-of-school suspensions";

      const barDatas: string[] = [];
      const barLabels: string[] = [];

      const finalData: any[] = [
    {
      label: "",
      data: [],
      backgroundColor: [
        "rgba(189, 218, 216)",
        "rgba(132, 153, 151)",
        "rgba(189, 191, 218)",
        "rgba(132, 138, 153)",
      ],
      borderColor: [
        "rgba(189, 218, 216)",
        "rgba(132, 153, 151)",
        "rgba(189, 191, 218)",
        "rgba(132, 138, 153)",
      ],
      borderWidth: 1,
    },
  ];

      this.school?.disciplineAndAttendance?.studentZeroSuspensionsSchoolPerformOverTime.sort()
      .splice(2,this.school?.disciplineAndAttendance?.studentZeroSuspensionsSchoolPerformOverTime?.length).forEach(
        (element) => {
          barLabels.push(`${+element.year - 1}-${element.year}`);
          barDatas.push(element.result);
        }
      );

      finalData[0].data = barDatas;

      if (this.chartIsCreated) {
        console.log("before destroy");

        (this.currentBarChart as Chart).data.labels = barLabels;
        (this.currentBarChart as Chart).data.datasets = finalData;
        (this.currentBarChart as Chart).update();
      } else {
        this.currentBarChart = drawBarChart(
          "barChart",
          barLabels,
          finalData,
          true
        );
        this.chartIsCreated = true;
      }
    }
  }

  getInversePercentValue(percentValue : string) {
    let result = "";

    if (percentValue)
    {
      if (percentValue == ">95%")
      {
        result = "<5";
      }
      else if (percentValue == "<5%")
      {
        result = ">95";
      }
      else if (isNaN(Number(percentValue)))
      {
        result = percentValue;
      }
      else
      {
        result = (100 - parseInt(percentValue)).toString();
      }
    }    

    return result;
  }

  //EDL-10646
  getZeroSuspensionPercentValue(percentValue : string) {
    let result = "";

    if (percentValue)
    {
      if (percentValue == ">95%")
      {
        result = ">95";
      }
      else if (percentValue == "<5%")
      {
        result = "<5";
      }
      else
      {
        result = percentValue;
      }
    }     
    return result;
  }

  //EDL-10646
  isValidPercentValue(percentValue : string) {
    let result = false;

      if (percentValue == ">95%" || percentValue == "<5%" || !isNaN(Number(percentValue)))
      {
        result = true;
      }
      else
      {
        result = false;
      } 
    return result;
  }
}

